:root{
  --element-size: 40px;
  --element-size-lg: 80px;
  --element-size-xl: 160px;
  --spacing: 10px;
  --spacing-sm: 5px;
}
$bg-color: #111317;
$shadow: 3px;

@mixin setBg(){
  background: linear-gradient(135deg, rgba(255,166,0,1) 0%, rgba(39,179,160,1) 30%, rgba(80,163,119,1) 47%, rgba(61,101,193,1) 65%, rgba(67,97,191,1) 66%, rgba(144,43,171,1) 79%, rgba(255,0,0,1) 100%);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@mixin boxshadow($inset: true) {
  $brdr-str: 0 0 var(--spacing-sm) rgba($bg-color, 0.4);
  @if $inset {
    $brdr-str: inset 0 0 var(--spacing-sm) rgba($bg-color, 0.4);
  }
  -webkit-box-shadow: $brdr-str;
  -moz-box-shadow: $brdr-str;
  box-shadow: $brdr-str;
}

body {
  background: $bg-color;
  color: white;
  font-family: 'Roboto Condensed', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}
ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  min-height: var(--element-size);
  display: flex;
  gap: var(--spacing);
  padding: var(--spacing-sm) var(--spacing);
  align-items: center;
  background-color: rgba(darken($bg-color, 50%), 0.8);
  margin-bottom: var(--spacing-sm);
  cursor: pointer;
  &.active {
    color: gold
  }
   > p {
     flex-grow: 1;
   }
}
p{
  margin: 0;
}

a,
a:link {
  text-decoration: none;
}
.loading {
  background: green !important;
}
.btn {
  min-width: var(--element-size);
  height: var(--element-size);
  line-height: var(--element-size);
  background: $bg-color;
  padding: 0 var(--spacing-sm);
  color: white;
  border: 0;
  border-radius: var(--spacing);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &-2nd{
    background: $bg-color;
  }
  &.lg{
    display: block;
    width: var(--element-size-lg);
    min-height: var(--element-size-lg);
    margin: var(--element-size) auto;
    @include boxshadow(false);
    position: relative;
  
      > i {
        font-size: var(--element-size);
      }
    
      > span {
        position: absolute;
        bottom: 5px;
        font-size: 10px;
        line-height: 12px;
        display: block;
        //text-align: center;
        width: 90%;//mumbo
      }
  }
  &.xl {
    min-width: var(--element-size-xl);
    height: var(--element-size-xl);
    line-height: var(--element-size-xl);
    border-radius: 50%;
    box-shadow: $shadow $shadow $shadow 0 rgba($bg-color, 0.8);
    position: relative;
      &::before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: inherit;
        @include boxshadow(false);
      }
      > i {
        font-size: var(--element-size);
        z-index: 1;
      }
  }
}
.xl {
  background-color: #53a238;
  margin: var(--element-size) 0;
}

.btn-set{
  display: flex;
  padding: var(--spacing);
  justify-content: center;
     > .btn-page {
       position: absolute;
       top: var(--spacing);
       left: var(--spacing);
     }
}
.msg{
  color: white;
  padding: var(--spacing);
  min-height: var(--element-size);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing);
  text-shadow: rgba($bg-color, 0.8) 2px 0 4px;
  cursor: pointer;
}

header,
section,
footer{
  background-color: lighten($bg-color, 20%);
  background-image: url('./assets/imgs/bg.jpg');
  background-size: 100% auto;
  border-radius: var(--spacing-sm);
  background-position: 50%;
  @include boxshadow();
}

section {
  flex-grow: 1;
}

#player{
  margin: var(--spacing) auto;
  &,
   > div {
    border-radius: var(--spacing);
  }
    > div {
      position: relative;//base page here
      display: flex;
      flex-direction: column;
      min-height: calc(100vh - var(--element-size));
      max-height: calc(100vh - var(--element-size));
      overflow: auto;
    }
   &.playing {
     padding: var(--spacing-sm);
   }
}

#player,
.page{
  max-width: 600px;
}

.playing {
  @include setBg();
  &.btn {
    box-shadow: inset $shadow $shadow $shadow 0 rgba($bg-color, 0.8);
    position: relative;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 5%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 5%;
  }
}

.ok{
  background: rgba(green, 0) !important;
  color: gold;
  &,
  &:before{
    box-shadow: none !important;
  }

}
.ok > i::before{
//@include setBg();
  background: -webkit-linear-gradient(gold, orange);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  box-shadow: none;
}

a.btn {
  background-color: transparent;
  display: flex;
  gap: var(--spacing);
}

///page
.page{
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;//var(--spacing);
  right: 0;//var(--spacing);
  bottom: 0;//var(--spacing);
  left: 0;//var(--spacing);
  z-index: 2;
  min-height: 100vh;
  > header,
  > section {
    padding: var(--spacing);
  }
   > header{
     display: flex;
     align-items: center;
     gap: var(--spacing);
     padding-bottom: var(--spacing);
       h2{
        margin: 0;
        padding: 0;
        text-align: right;
        flex-grow: 1;
       }
   }
  
    > section {
      background: $bg-color;
      color: white;
      flex-grow: 1;
    }
    > footer {
      padding: var(--spacing-sm);
      position: static !important;
    }
}


//overrides to external shit
.alamargin,
.visible-sm, .visible-md, .visible-lg,
.soittokerrat{
  display: none;
}
//.soittoaika {
//}
.kappale{
  .kappalelinkki{
    pointer-events: none;
    color: white;
  }
}
.playnappula{
  display: flex;
  img {
    display: none;
  }
  > a {
    display: inline-block;
    width: var(--element-size);
    height: var(--element-size);
    color: inherit;
    &,
    &:after{
      display: flex;
      justify-content: center;
      align-items: center;
      width: var(--element-size);
      height: var(--element-size);
    }
    &:after{
      font-family: 'Font Awesome 5 Brands';
      font-weight: 900;
      border: 1px solid white;
      border-radius: 50%;
    }
    &:first-child{
      margin-right: var(--spacing-sm);
      &:after{
        content: '\f167';
      }
    }
    &:last-child:not(:only-child):after{
      content: '\f1bc';
    }
  }
}
table {
  tr {
    &:nth-child(odd){
      background-color: lighten($bg-color, 20%);
    }
      > td {
        padding: var(--spacing-sm);
      }
  }
}